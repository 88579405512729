import 'alpinejs';
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';

const logos = new Swiper(".logo-carousel", {
  loop: true,
  centeredSlides: true,
  slidesPerView: 1,
  spaceBetween: 30,
  breakpoints: {
    1200: {
      slidesPerView: 3,
    },
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
});
